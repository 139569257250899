import React from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Box, Button } from '@mui/material';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import DrawIcon from '@mui/icons-material/Draw';
import theme from '../assets/themes';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles({
  buttonContainer: {
    display: 'flex',
    backgroundColor: `${theme.palette.primary.main} !important`,
    width: '350px',
    height: '45px',
    alignItems: 'center',
    justifyContent: 'space-around',
    borderRadius: '10px',
    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px, rgba(27, 133, 219, 0.5) 5px 10px 15px',
  },
  button: {
    outline: '0 !important',
    border: '0 !important',
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    transition: 'all ease-in-out 0.3s',
    cursor: 'pointer',
    '&:hover': {
      transform: 'translateY(-3px)',
    },
  },
  icon: {
    fontSize: '20px',
  },
});

function Homebar() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { courseCode, lectureId } = useParams();

  const handleDrawClick = () => {
    navigate(`/Productpage/courses/${courseCode}/lectures/${lectureId}/draw`);
  };

  const handleConfusionClick = () => {
    navigate(`/Productpage/courses/${courseCode}/lectures/${lectureId}/confusion`);
  };

  const handleProfileDashClick = () => {
    navigate(`/Productpage/courses/${courseCode}/lectures/${lectureId}/ProfLecDash`);
  };

  return (
    <Box className={classes.buttonContainer}>
      <Button className={classes.button} onClick={handleConfusionClick}>
        <QuestionMarkIcon style={{color: '#f2f2f2'}}/>
      </Button>
      <Button className={classes.button} onClick={handleProfileDashClick}>
        <QuestionAnswerIcon style={{color: '#f2f2f2'}}/>
      </Button>
      <Button className={classes.button} onClick={handleDrawClick}>
        <DrawIcon style={{color: '#f2f2f2'}}/>
      </Button>
    </Box>
  );
}

export default Homebar;