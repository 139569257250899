import { initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator } from 'firebase/auth';  // ADDED
import { getStorage, connectStorageEmulator } from 'firebase/storage';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getDatabase, connectDatabaseEmulator } from 'firebase/database';  // ADDED
import { getApp } from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyDtVUwVl9ua-Kzke8aEYwQzEaKUwvLvoBg",
    authDomain: "engauge-77e8b.firebaseapp.com",
    databaseURL: "https://engauge-77e8b-default-rtdb.firebaseio.com",
    projectId: "engauge-77e8b",
    storageBucket: "engauge-77e8b.appspot.com",
    messagingSenderId: "209606143648",
    appId: "1:209606143648:web:81edb1a4c996322f4be3c5",
    measurementId: "G-Y4PW3KFK2Q"
};

// Initialize Firebase app
const app = initializeApp(firebaseConfig);

// Initialize Firebase services
const auth = getAuth(app);
const storage = getStorage(app);
const db = getFirestore(app);
const functions = getFunctions(getApp());
const realtimeDb = getDatabase(app);  // ADDED

// Handle both 'localhost' and '127.0.0.1' for local development
if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
    connectFirestoreEmulator(db, "127.0.0.1", 8080);
    connectFunctionsEmulator(functions, "127.0.0.1", 5001);
    connectStorageEmulator(storage, "127.0.0.1", 9199);
    connectDatabaseEmulator(realtimeDb, "127.0.0.1", 9098);
    connectAuthEmulator(auth, "http://127.0.0.1:9099");

    console.log("Connected to Firebase emulators");
} else {
    console.log("Using production Firebase services");
}

if (process.env.REACT_APP_USE_EMULATOR === 'true') {
    console.log('Connecting to emulators...');
    connectFirestoreEmulator(db, 'localhost', 8080);
    connectFunctionsEmulator(functions, 'localhost', 5001);
} else {
    console.log('Using production environment');
}

export { app, auth, storage, db, realtimeDb, functions };
