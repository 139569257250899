import React from "react";
import theme from "../assets/themes";
import { Box, Button, Typography, Drawer } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from 'react-router-dom';
import SettingsIcon from '@mui/icons-material/Settings';
import HomeIcon from '@mui/icons-material/Home';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import LogoutIcon from '@mui/icons-material/Logout';
import QrCodeIcon from '@mui/icons-material/QrCode';

const useStyles = makeStyles(() => ({
  sideBarContainer: {
    height: "100%",
    minWidth: "240px", // Set a fixed width
    backgroundColor: "#6a94ef",
    display: "flex",
    flexDirection: "column",
    paddingTop: "1rem",
    gap: "1.5rem",
    overflowY: 'auto', // Changed from 'hidden' to 'auto'
  },
  text: {
    textTransform: "capitalize",
    fontWeight: 600,
    color: "white",
    paddingTop: "2px",
  },
  button: {
    display: "flex",
    justifyContent: 'flex-start !important',
    paddingLeft: '1.25rem !important',
    textTransform: "none",
    color: `${theme.palette.white.main} !important`,
  },
  logoutContainer: {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    bottom: 0,
    width: '100%',
  },
}));

function Sidebar({sidebarOpen, toggleSidebar}) {
    const classes = useStyles();
    const navigate = useNavigate();

    const handleLogout = () => {
      // Clear authentication state
      // Remove user_id from localStorage
      localStorage.removeItem('user_id'); 
      // Remove isLoggedIn from localStorage
      localStorage.removeItem('isLoggedIn'); 
  
      // Redirect to home page
      navigate('/');
    };

    return (
      <Drawer
        anchor="left"
        open={sidebarOpen}
        onClose={toggleSidebar}
        variant="temporary"
        ModalProps={{
          keepMounted: true, // Better open performance on mobile
        }}
        sx={{
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: '240px', // Match the minWidth of sideBarContainer
            transition: theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
          },
        }}
      > 
        <Box className={classes.sideBarContainer}>
          <Button
            className={classes.button}
            variant="text"
            size="small"
            startIcon={<HomeIcon />}
            onClick={() => navigate("/Productpage/courses")}
          >
            <Typography className={classes.text}>Home</Typography>
          </Button>
          <Button
            className={classes.button}
            variant="text"
            size="small"
            startIcon={<QuestionAnswerIcon />}
            onClick={() => navigate("/Productpage/Engagement")}
          >
            <Typography className={classes.text}>Lecture Engagement</Typography>
          </Button>
          <Button
            className={classes.button}
            variant="text"
            size="small"
            startIcon={<FolderSpecialIcon />}
            onClick={() => navigate("/Productpage/archived-courses")}
          >
            <Typography className={classes.text}>Archived Classes</Typography>
          </Button>
          <Button
            className={classes.button}
            variant="text"
            size="small"
            startIcon={<SettingsIcon />}
            onClick={() => navigate("/Productpage/settings")}
          >
            <Typography className={classes.text}>Settings</Typography>
          </Button>
          <Box className={classes.logoutContainer}>
            <Button
              className={classes.button}
              variant="contained"
              startIcon={<LogoutIcon />}
              fullWidth
              sx={{
                bgcolor: `${theme.palette.primary.main}`,
              }}
              onClick={handleLogout}
            >
              <Typography className={classes.text}>Logout</Typography>
            </Button>
          </Box>
        </Box>
      </Drawer>
    );
}

export default Sidebar;